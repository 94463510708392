import React, { useEffect } from "react"
import { FaUser, FaStoreAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from "react-redux"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card/card"
import { selectCategory, onChangeByName } from "../redux/registration/registrationAction"
import { getCategories } from "../redux/api/apiAction"

const MemberPage = () => {
    const dispatch = useDispatch()
    const { categories } = useSelector(({ apiReducer }) => apiReducer)

    useEffect(() => {
        dispatch(getCategories())
    }, [])

    const onClick = (nonMember = false) => {
        dispatch(onChangeByName("unionMember", nonMember))
        dispatch(selectCategory(categories, nonMember ? nonMember : "CFP_medecin"))
    }

    return (
        <Layout>
            <SEO title="Inscription" />
            <div className={"row justify-content-around"}>
                <Card title={"CFP"} texte={"14<sup>e</sup> Congrès Français de Psychiatrie"} link={"/cfp"} onClick={() => onClick()} />
                <Card title={"JSIRP"} texte={"5<sup>e</sup> Journée Sciences Infirmières et Recherche Paramédicale"} link={"/individual"} onClick={() => onClick("JSIRP")} />
                <Card title={"JACC"} texte={"5<sup>e</sup> Journée de l'ACCompagnement et de l'action médico-sociale"} link={"/individual"} onClick={() => onClick("JACC")} />
                <Card title={"JPPA"} texte={"7<sup>e</sup> Journées de Psychiatrie de la Personne Âgée"} link={"/individual"} onClick={() => onClick("JPPA")} />
            </div>
        </Layout>
    )
}

export default MemberPage
